import { ALL_CUSTOMBATCH_EXPORT } from "../constant/ActionType";

export default function CustomBatchExportData(
    state = {
        exportcustombatchdata: [],
        statusCode: null,
    },
    action
) {
    switch (action.type) {
        case ALL_CUSTOMBATCH_EXPORT:
            return {
                ...state,
                exportcustombatchdata: action.data,
                statusCode: action.status,
            };

        default:
    }

    return state;
}
