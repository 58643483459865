
import {
    ALL_CUSTOM_BATCHES, VIEW_CUSTOM_BATCH
} from "../constant/ActionType";

export default function AllCustomBatchReducers(state = {
    allcustomBatch: [],
    viewcustomBatch: [],

}, action) {
    switch (action.type) {
        case ALL_CUSTOM_BATCHES:
            return {
                ...state,
                allcustomBatch: action?.data,

            };
        case VIEW_CUSTOM_BATCH:
            return {
                ...state,
                viewcustomBatch: action?.data?.data
            };

        default:
    }

    return state;
}