import config from "../../config";

export const base_url = "https://etibarwins.com/Admin/api/";
export const temp_url = "https://etibarwins.com/Admin/api/";

// export const base_url = "https://etibarwins.alisonstech-dev.com/Admin/api/";
// export const temp_url = "https://etibarwins.alisonstech-dev.com/Admin/api/";

export const encryptionSecret = config.encryptionSecret;

export const logInApii = "login";
export const loginAuthh = "verifyLogin";
export const ForgetPassword = "Password/GetOTP";
export const ChangePassword = "User/Password/change";
export const VerifyOTP = "Password/VerifyOTP";
export const registerBrandOwner = "Brand/create";
export const registerRollsApi = "Role/create-role";
export const editRoleAPI = "Role/Update";
export const registerbranduser = "User/register";
export const allUsersApi = "User/get";
export const usersStatusUpdateApi = "User/update";
export const edituserapis = "User/update";
export const allDropdownActiveCompanies = "Company/get/active";
export const allDropdownActiveBrand = "Brand/get/active";
export const registerProduct = "Products/create";
export const allProductApi = "Products/getAll";
export const allProductActiveApi = "Products/get/active";
export const ProductStatusUpdateApi = "Products/update";
export const editProductApi = "Products/update";
export const updateVerfiyLimit = "Products/updateVerfiyLimit";
export const brandupdatestatusApi = "Brand/update";
export const allRoleApi = "Role/get-roles";
export const assignpermissionsApi = "Permission/assign-permission";
export const getRolePermissionsApi = "Permission/get-rolepermissions";
export const allpermissionsApi = "Permission/get-allpermissions";
export const getAllBrands = "Brand/getAll";
export const brandeditapi = "Brand/get";
export const getsallcategeory = "Category/index";
export const update_status_code = "RandomCodes/update-status";
export const updatestatuscategory = "Category/status-update";
export const cateapplyregister = "Category/store";
export const editingcategory = "Category/update";
export const allsubcategroiess = "SubCategory/index";
export const subcategoryupdatestatus = "SubCategory/status-update";
export const subcategoryEdit = "SubCategory/update";
export const createSubcategory = "SubCategory/store";
export const companygetall = "Company/getAll";
export const createcompany = "Company/register";
export const companystatusupdate = "Company/update";
export const companydetailing = "Company/get";
export const getAllCounts = "Admin/Dashboard";
export const getRandomCodes = "RandomCodes/index";
export const postRandomCodes = "PrePost/store";
export const GetAllBatch = "Batches/getAll";
export const batchStatusUpdate = "Batches/update";
export const batchProductSerialNo = "Batches/Product/availableSerialNo/";
export const postBatchAPI = "Batches/create";
export const postUpdateBatchAPI = "Batches/update";
export const getBatchByProductID = "Batches/getBatchesByProduct/";
export const GetAllcustommBatch = "Batches/custom/getAll";
export const GetAllcustommBatchEx = "Batches/custom/getAll?paginate=all";
export const CustomBatch_API = "Batches/custom/create";
export const postUpdatecustomBatch_API = "Batches/custom/update";
export const ViewAllcustommBatch_api = "Batches/get/custom/";
export const GetAllCustomer_api = "customer/get";
export const Customer_API = "customer/create";
export const Customer_edit_API = "customer/update";
export const GetActiveCustomer_api = "customer/get/active";
export const activeStatuscustomer_api = "customer/update/";
export const getSms = "sms/get";
export const createSMS = "Replay/store";
export const SmsReport = "Report/sms";
export const QrcodeReport = "Report/qr";
export const MapApi = "Report/qr/map";
export const ReportProblem = "ReportProblem";
export const BatchesGetAll_api = "Batches/both/getAll";
export const smsReply = "Reply/getAll";
export const createsmsReply = "Reply/store";
export const editsmsReply = "Reply/edit";
export const StatusSmsReply = "Reply/update";
export const ColorShift = "getColorShifting";
export const getColorShift = "getColorShiftingWeb";
export const UpdateFAQs = "faqs/update";
export const UpdateTerms = "Terms/update";
export const GetFAQs = "faqs";
export const GETTerms = "Terms";
export const Progressive = "http://etibarwins.com:5000/progress/";
export const csv_url = "RandomCodes/csv";
export const csv_file = "https://etibarwins.com/Admin/downloadFile/";
export const prepostapii = "prepost/id";
